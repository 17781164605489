import React from "react";
import { Button } from "react-bootstrap";
import axios from "axios";
import { useHistory } from "react-router-dom";

const LogoutButton = () => {
  const history = useHistory();

  const handleLogout = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/v1/admin/logout`,
        {},
        {
          withCredentials: true,
        }
      );
      if (response.data.success) {
        localStorage.removeItem("token"); // Clear token from localStorage
        history.push("/login"); // Redirect to login page
      } else {
        console.error("Logout failed:", response.data.msg);
      }
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  return (
    <Button
      variant="outline-danger"
      className="btn-icon btn-icon-start ms-0 ms-sm-1 w-100 w-md-auto"
      onClick={handleLogout}
    >
      <span>Logout</span>
    </Button>
  );
};

export default LogoutButton;
